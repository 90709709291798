import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import FingoDialog from './FingoDialog';

const DialogWithTextField = ({
  openDialog,
  onCloseDialog,
  title,
  onSubmit,
  loadingSubmit,
  textFieldLabel,
  textInput,
  setTextInput,
}) => (
  <FingoDialog
    open={openDialog}
    handleClose={onCloseDialog}
    title={title}
    fullWidth
    dialogActionButton={(
      <LoadingButton
        onClick={onSubmit}
        loading={loadingSubmit}
        color="primary"
        variant="contained"
        size="small"
      >
        Confirmar
      </LoadingButton>
    )}
  >
    <TextField
      id="operative-request-reason"
      label={textFieldLabel}
      variant="outlined"
      onChange={setTextInput}
      value={textInput}
      multiline
      rows={3}
      fullWidth
    />
  </FingoDialog>
);

DialogWithTextField.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  textInput: PropTypes.string.isRequired,
  setTextInput: PropTypes.func.isRequired,
  title: PropTypes.string,
  loadingSubmit: PropTypes.bool,
  textFieldLabel: PropTypes.string,
};

DialogWithTextField.defaultProps = {
  title: 'Ingrese respuesta',
  loadingSubmit: false,
  textFieldLabel: 'Ingrese respuesta',
};

export default DialogWithTextField;
