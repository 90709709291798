import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ADD_COMPANIES_TO_BLACKLIST } from '../../graphql/customers';
import { useBooleanState } from '../../hooks';

const RiskCommentsActions = ({ actions, masterEntityId }) => {
  const [text, setText] = useState(actions[0]);
  const [showEdit, setShowEdit] = useBooleanState(false);
  const [editCompanyBlacklistComment] = useMutation(
    ADD_COMPANIES_TO_BLACKLIST,
    {
      variables: { comments: [text], identifiers: [masterEntityId], searchType: 'id' },
      onCompleted: setShowEdit,
    },
  );
  const comments = actions[0];
  return (
    <List>
      <>
        {!showEdit ? (
          <div>
            {!comments ? (
              <ListItem sx={{ marginBottom: 1 }}>
                <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                  Esta empresa no tiene comentarios asociados
                </Typography>
              </ListItem>
            ) : (comments.split('\n').map((comment) => (
              <ListItem sx={{ marginBottom: 1 }} key={comment}>
                <Typography variant="body1">
                  {comment}
                </Typography>
              </ListItem>
            )))}
            <Divider />
            <ListItem sx={{ marginTop: 1 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={setShowEdit}
              >
                {`${comments ? 'Editar' : 'Agregar'}  Comentario`}
              </Button>
            </ListItem>
          </div>
        ) : (
          <div>
            <ListItem>
              <TextField
                sx={{ width: '100%' }}
                variant="outlined"
                name="rut"
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={3}
              />
            </ListItem>
            <ListItem sx={{ marginLeft: '25%' }}>
              <Button
                sx={{ marginRight: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={editCompanyBlacklistComment}
                disabled={!text}
              >
                Guardar
              </Button>
              <Button
                sx={{ marginLeft: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={setShowEdit}
              >
                Cancelar
              </Button>
            </ListItem>
          </div>
        )}
      </>
    </List>
  );
};

RiskCommentsActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  masterEntityId: PropTypes.string.isRequired,
};

export default RiskCommentsActions;
